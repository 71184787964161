import React, { useState } from 'react'
import { inject, observer } from 'mobx-react'
import { compose } from 'recompose'
import SelectInvolvedPartyModal from 'pages/Mission/SelectInvolvedPartyModal'

const RemoteAssessment = ({
  RemoteAssessmentCtrl: {
    showRemoteAssessmentForm,
    phoneNumber,
    email,
    startSession,
    checkInvitationStatus,
    setProperty,
    involvedParty,
    sending,
    resetForm,
  },
  PartyInvolvedStore: { asSelectOptions, findEmail, findMobilePhone },
  UserStore: { isClaimManager },
  ManagerClaimStore,
  MissionStore,
}) => {
  const id = isClaimManager ? ManagerClaimStore.id : MissionStore.id
  const [remoteAssessment, setRemoteAssessment] = useState(false)
  const [termsAndConditions, setTermsAndConditions] = useState(true)
  const onClose = () => {
    setProperty('showRemoteAssessmentForm', false)
    resetForm()
  }
  const onChange = e => {
    let inputType = 'phoneNumber'
    let value = e
    if (e !== undefined && e.target !== undefined) {
      inputType = e.target.name
      value = e.target.value
    }
    setProperty(inputType, value)
  }
  const onChangeIP = e => {
    const email = findEmail(e.target.value)
    const phoneNumber = findMobilePhone(e.target.value)
    setProperty('email', email ? email : '')
    setProperty('phoneNumber', phoneNumber ? phoneNumber : '')
    setProperty('involvedParty', e.target.value)
  }

  const onSetTermsAndConditions = state => {
    setTermsAndConditions(state)
  }

  const onRemoteAssessment = state => {
    setRemoteAssessment(state)
  }

  const onValid = async () => {
    if ((await startSession(id)) !== 400) onRemoteAssessment(true)
  }

  const onTermsAndConditions = async () => {
    const res = await checkInvitationStatus(id)
    if (res.data.attributes.expertSessionUrl !== null) {
      window.open(res.data.attributes.expertSessionUrl, '_blank').focus()
    } else {
      onSetTermsAndConditions(false)
    }
  }

  return (
    <SelectInvolvedPartyModal
      showModal={showRemoteAssessmentForm}
      onClose={onClose}
      onChange={onChange}
      onValid={onValid}
      onChangeIP={onChangeIP}
      optionsIP={asSelectOptions}
      selectedIP={involvedParty}
      email={email}
      phoneNumber={phoneNumber}
      loading={sending}
      onRemoteAssessment={onRemoteAssessment}
      remoteAssessment={remoteAssessment}
      onTermsAndConditions={onTermsAndConditions}
      termsAndConditions={termsAndConditions}
      setTermsAndConditions={onSetTermsAndConditions}
    />
  )
}

export default compose(
  inject(
    'MissionStore',
    'UserStore',
    'RemoteAssessmentCtrl',
    'PartyInvolvedStore',
    'ManagerClaimStore',
  ),
  observer,
)(RemoteAssessment)
